import first from '../assets/photo/1.webp';
import second from '../assets/photo/2.webp';
import third from '../assets/photo/3.webp';
import fourth from '../assets/photo/4.webp';
import fifth from '../assets/photo/5.webp';

const photoList = [
  {
    id: 0,
    title: '',
    img: first,
  },
  {
    id: 1,
    title: '',
    img: second,
  },
  {
    id: 2,
    title: '',
    img: third,
  },
  {
    id: 3,
    title: '',
    img: fourth,
  },
  {
    id: 4,
    title: '',
    img: fifth,
  },
];

export default photoList;
