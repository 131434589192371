const wayList = [
  {
    id: 0,
    text: 'В августе 2022 года заинтересовался Frontend-разработкой и начал поиски подходящего обучения',
  },
  {
    id: 1,
    text: 'В сентябре 2022 года начал проходить тренажёры от HTML Academy',
  },
  {
    id: 2,
    text: 'В ноябре 2022 года приобрел курсы в HTML Academy и начал полноценное погружение в сферу',
  },
  {
    id: 3,
    text: 'В январе 2023 года закончил свой первый проект - Sedona',
  },
  {
    id: 4,
    text: 'В апреле 2023 года закончил свой второй проект - Cat Energy',
  },
  {
    id: 5,
    text: 'В июне 2023 года закончил свой третий проект - Kekstagram',
  },
  {
    id: 6,
    text: 'В августе 2023 года закончил свой четвертый проект - Farmer Products',
  },
  {
    id: 7,
    text: 'В сентябре 2023 года закончил курс Frontend-разработчик от HTML Academy',
  },
  {
    id: 8,
    text: 'В сентябре 2023 года начал второй курс - JavaScript-разработчик',
  },
  {
    id: 9,
    text: 'Написал свой сайт-резюме на ReactJS, на котором Вы сейчас и находитесь',
  },
  {
    id: 10,
    text: 'В ноябре 2023 года начал работать в компании "TravelLine" (разработчик IT-инструментов для отельного бизнеса)',
  },
];

export default wayList;
